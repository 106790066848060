import { useState } from "react";
import { ApiService } from "../ApiService";

const apiService = new ApiService();
export const Form = () => {
  const [email, setEmail] = useState("");
  const [membershipNumber, setMembershipNumber] = useState();
  const [apiResponse, setApiResponse] = useState(null);

  const onEmailChange = (e) => setEmail(e.target.value);
  const onMembershipNumberChange = (e) => setMembershipNumber(e.target.value);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = async () => {
    if (!validateEmail(email)) {
      return;
    }

    try {
      const response = await apiService.generateApplePass(
        email,
        membershipNumber
      );
      setApiResponse(response.status);
    } catch (error) {
      console.log(error);
      setApiResponse(error.response.status);
    }
  };

  return (
    <div className="Containers">
      <div className="Labels">Email Address</div>
      <input
        className="Inputs"
        onChange={onEmailChange}
        value={email}
        type="text"
        name="email"
      />

      <div className="Labels">Membership Number</div>
      <input
        className="Inputs"
        onChange={onMembershipNumberChange}
        value={membershipNumber}
        type="text"
        name="membership_number"
      />

      <div className="ButtonDiv">
        <button className="Buttons" onClick={onSubmit}>
          Submit
        </button>
      </div>

      {apiResponse === 201 && <div>Success</div>}

      {apiResponse && apiResponse !== 201 && (
        <div>Bad Request, check passed info</div>
      )}
    </div>
  );
};

export default Form;
