import axios from 'axios';

export class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Request-Token': process.env.REACT_APP_API_REQUEST_TOKEN
            },
        });
    }
    generateApplePass(email, membershipNumber) {
        return this.api.post('/applewalletdemo', {
            email,
            membershipNumber,
        });
    }
}